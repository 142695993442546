import React from 'react'

export function allButLastWord(phrase) {
  if (!phrase) {
    return phrase
  }
  return phrase.trim().split(' ').slice(0, -1).join(' ') + ' '
}

export function justTheLastWord(phrase) {
  if (!phrase) {
    return phrase
  }
  const words = phrase.trim().split(' ')
  return words[words.length - 1]
}

export function formatPrice(price) {
  if (!Number.isFinite(price)) return price

  return <span className="price-number">£{price.toFixed(2)}</span>
}
