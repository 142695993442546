import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { formatPrice } from '../components/util'

const SetMenusTemplate = ({ title, setMenus }) => (
  <div className="menu set-menus">
    <div className="menu-content">
      <h1>{title}</h1>
      <div className="set-menus-wrapper">
        {setMenus && setMenus.map((setMenu) => (
          <div className="set-menu">
            <h2>{setMenu.title}</h2>
            <p>
              <span>{formatPrice(setMenu.price)} per person</span>
              {setMenu.minimumPeople > 1 ? (
                <span>, minimum {setMenu.minimumPeople} people</span>
              ) : (
                ''
              )}
            </p>
            {setMenu.sections && setMenu.sections.map(({ section }) => (
              <>
                <hr></hr>
                {section.title && <h3>{section.title}</h3>}
                {section.items && (
                    <ul>
                    {section.items.split('\n').map((item) => (
                        <li>{item}</li>
                    ))}
                    </ul>
                )}
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
)

SetMenusTemplate.propTypes = {
  chineseName: PropTypes.string,
  englishName: PropTypes.string,
  spiceLevel: PropTypes.number,
  price: PropTypes.number,
}

const SetMenus = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter

  return (
    <Layout>
      <SetMenusTemplate
        setMenus={frontmatter.setMenus}
        title={frontmatter.title}
      />
    </Layout>
  )
}

SetMenus.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default SetMenus

export const pageQuery = graphql`
  query SetMenusById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        setMenus {
          title
          price
          minimumPeople
          sections {
            section {
              title
              items
            }
          }
        }
      }
    }
  }
`
